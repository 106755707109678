export const addgaClientId = (store, gaClientId) => {
  store.state.gaClientId = gaClientId;
  store.setState(store.state);
};

export const addUniqueIdentifier = (store, uniqueIdentifier) => {
  store.state.uniqueIdentifier = uniqueIdentifier;
  store.setState(store.state);
};

export const addGetStartedBirthCertificate = (
  store,
  getStartedBirthCertificateData
) => {
  store.state.getStartedBirthCertificateData = getStartedBirthCertificateData;
  store.setState(store.state);
};

export const addGetStartedDeathCertificate = (
  store,
  getStartedDeathCertificateData
) => {
  store.state.getStartedDeathCertificateData = getStartedDeathCertificateData;
  store.setState(store.state);
};

export const addGetStartedFetalDeathCertificateData = (
  store,
  getStartedFetalDeathCertificateData
) => {
  store.state.getStartedFetalDeathCertificateData = getStartedFetalDeathCertificateData;
  store.setState(store.state);
};

export const addFetalDeathCertificateInfo = (
  store,
  fetalDeathCertificateInfoData,
  index = ""
) => {
  if (index !== "") {
    store.state.totalOrderRecords[index] = fetalDeathCertificateInfoData;
    store.setState(store.state);
  } else {
    store.state.totalOrderRecords.push(fetalDeathCertificateInfoData);
    store.setState(store.state);
  }
};

export const addJwtToken = (store, jwtToken) => {
  store.state.jwtToken = jwtToken;
  store.setState(store.state);
};

export const addVerifyIdentity = (store, verifyIdentityData) => {
  store.state.verifyIdentityData = verifyIdentityData;
  store.setState(store.state);
};

export const addBirthCertificateInfo = (
  store,
  birthCertificateInfoData,
  index = ""
) => {
  if (index !== "") {
    store.state.totalOrderRecords[index] = birthCertificateInfoData;
    store.setState(store.state);
  } else {
    store.state.totalOrderRecords.push(birthCertificateInfoData);
    store.setState(store.state);
  }
};

export const receiptOrder = (store, receiptData) => {
  store.state.receiptData = receiptData;
  store.setState(store.state);
};

export const shippingInformationData = (store, shippingInformationData) => {
  store.state.shippingInformationData = shippingInformationData;
  store.setState(store.state);
};

export const paymentFormData = (store, paymentFormData) => {
  store.state.paymentFormData = paymentFormData;
  store.setState(store.state);
};

export const addOrderNumber = (store, orderNumber) => {
  store.state.orderId = orderNumber;
  store.setState(store.state);
};

export const addItemizedTotalPayment = (store, paymentItemizedTotal) => {
  store.state.paymentItemizedTotal = paymentItemizedTotal;
  store.setState(store.state);
};

export const addAmountDistribution = (store, amountDistribution) => {
  store.state.amountDistribution = amountDistribution;
  store.setState(store.state);
};

export const addCheckOutOrder = (store, checkOutOrder) => {
  store.state.checkOutOrder = checkOutOrder;
  store.setState(store.state);
};

export const addDocumentTypeFlagOne = (store, showFlag) => {
  store.state.documentTypeFlagOne = showFlag;
  store.setState(store.state);
};
export const addDocumentTypeFlagTwo = (store, showFlag) => {
  store.state.documentTypeFlagTwo = showFlag;
  store.setState(store.state);
};

export const getStarteDeathVerificationInfo = (
  store,
  getStarteDeathVerificationInfo
) => {
  store.state.getStarteDeathVerificationInfo = getStarteDeathVerificationInfo;
  store.setState(store.state);
};

export const deathVerificationInfo = (
  store,
  deathVerificationInfo,
  index = ""
) => {
  if (index !== "") {
    store.state.totalOrderRecords[index] = deathVerificationInfo;
  } else {
    store.state.totalOrderRecords.push(deathVerificationInfo);
  }
  store.state.saveEditIndex.arrayIndex = "";
  store.state.saveEditIndex.referenceType = "";

  store.setState(store.state);
};

export const setdeathVerification = (store, setdeathVerification) => {
  store.state.totalOrderRecords.push(setdeathVerification);
};
export const addPublicHost = (store, publicHost) => {
  store.state.publicHost = publicHost;
  store.setState(store.state);
};

export const divorceVerificationInfo = (
  store,
  divorceVerificationInfoData,
  index = ""
) => {
  if (index !== "") {
    store.state.totalOrderRecords[index] = divorceVerificationInfoData;
  } else {
    store.state.totalOrderRecords.push(divorceVerificationInfoData);
  }
  store.state.saveEditIndex.arrayIndex = "";
  store.state.saveEditIndex.referenceType = "";
  store.setState(store.state);
};

export const getStartedDivorceVerification = (
  store,
  getStartedDivorceVerificationInfoData
) => {
  store.state.getStartedDivorceVerificationInfoData = getStartedDivorceVerificationInfoData;
  store.setState(store.state);
};
export const getStartedBirthVerificationInfo = (
  store,
  getStartedBirthVerificationInfo
) => {
  store.state.getStartedBirthVerificationInfo = getStartedBirthVerificationInfo;
  store.setState(store.state);
};

export const birthVerificationInfo = (
  store,
  birthVerificationInfo,
  index = ""
) => {
  if (index !== "") {
    store.state.totalOrderRecords[index] = birthVerificationInfo;
    store.state.saveEditIndex.arrayIndex = "";
    store.state.saveEditIndex.referenceType = "";
  } else {
    store.state.totalOrderRecords.push(birthVerificationInfo);
  }
  store.setState(store.state);
};

export const getStartedMarriageVerificationInfo = (
  store,
  getStartedMarriageVerificationInfo
) => {
  store.state.getStartedMarriageVerificationInfo = getStartedMarriageVerificationInfo;
  store.setState(store.state);
};

export const marriageVerificationInfo = (
  store,
  marriageVerificationInfo,
  index = ""
) => {
  if (index !== "") {
    store.state.totalOrderRecords[index] = marriageVerificationInfo;
    store.state.saveEditIndex.arrayIndex = "";
    store.state.saveEditIndex.referenceType = "";
  } else {
    store.state.totalOrderRecords.push(marriageVerificationInfo);
  }
  store.setState(store.state);
};

export const orderStatusRemit = (store, orderStatusRemitData) => {
  store.state.orderStatusRemitData = orderStatusRemitData;
  store.setState(store.state);
};

export const addDeathCertificateInfo = (
  store,
  deathCertificateInfoData,
  index = ""
) => {
  if (index !== "") {
    store.state.totalOrderRecords[index] = deathCertificateInfoData;
    store.state.saveEditIndex.arrayIndex = "";
    store.state.saveEditIndex.referenceType = "";
  } else {
    store.state.totalOrderRecords.push(deathCertificateInfoData);
  }
  store.setState(store.state);
};

export const orderReviewUpdate = (store, donation) => {
  store.state.orderReview.donationChecked = donation;
  store.setState(store.state);
};

export const updateTotalOrderRecords = (store, orderRecord) => {
  store.state.totalOrderRecords = [];
  store.state.totalOrderRecords = orderRecord;
  store.setState(store.state);
};

export const totalOrderRecords = (store, orderRecord) => {
  store.state.totalOrderRecords.push(orderRecord);
  store.setState(store.state);
};

export const referenceData = (store, refreneceData, refrenceKey) => {
  if (refrenceKey === "stateList")
    store.state.referenceDataList.stateList.push(refreneceData);
  else if (refrenceKey === "countyList")
    store.state.referenceDataList.countyList.push(refreneceData);
  store.setState(store.state);
};

export const saveEditIndex = (store, editIndex, recieptType) => {
  store.state.saveEditIndex.arrayIndex = editIndex;
  store.state.saveEditIndex.referenceType = recieptType;
  store.setState(store.state);
};

export const clearGlobalState = (store) => {
  store.state.getStartedBirthCertificateData = {
    dateOfBirth: "",
    selectedState: { label: "", value: "" },
    selectedRelation: { label: "", value: "" },
  };
  store.state.birthCertificateInfoData = {
    selectedGender: { label: "", value: "" },
    countyOfBirth: { label: "", value: "" },
    selectedBirthCertificate: { label: "", value: "" },
    orderDetails: [
      {
        recordType: "",
        numberOfCopies: 0,
      },
    ],
    selectedRelation: { label: "", value: "" },
    selectedState: { label: "", value: "" },
    certificateInfoType: "birthCertificate",
    uid: "",
    lastName: "",
    firstName: "",
    dateOfBirth: "",
    selectedPurposeList: { label: "", value: "" },
    selectedPurposeList2: { label: "", value: "" },
    selectedPurposeList3: { label: "", value: "" },
  };
  store.state.getStartedBirthVerificationInfo = {
    dateOfBirth: "",
    selectedState: { label: "", value: "" },
  };
  store.state.birthVerificationInfo = {
    certificateInfoType: "birthVerification",
    uid: "",
    selectedGender: { label: "", value: "" },
    countyOfBirth: { label: "", value: "" },
    selectedState: { label: "", value: "" },
    dateOfBirth: "",
  };
  store.state.getStartedMarriageVerificationInfo = {
    dateOfMarriage: "",
    selectedState: { label: "", value: "" },
  };
  store.state.marriageVerificationInfo = {
    certificateInfoType: "marriageVerification",
    uid: "",
    countyOfMarriage: { label: "", value: "" },
    selectedState: { label: "", value: "" },
  };
  store.state.getStartedDivorceVerificationInfoData = {
    dateOfDivorce: "",
    selectedState: { label: "", value: "" },
  };
  store.state.divorceVerificationInfoData = {
    certificateInfoType: "divorceVerification",
    uid: "",
    countyOfDivorce: {
      label: "",
      value: "",
    },
    dateOfDivorce: "",
  };
  store.state.getStartedDeathCertificateData = {
    dateOfDeath: "",
    selectedState: { label: "", value: "" },
    selectedRelation: { label: "", value: "" },
  };
  store.state.deathCertificateInfoData = {
    certificateInfoType: "deathCertificate",
    uid: "",
    selectedGender: { label: "", value: "" },
    countyOfDeath: { label: "", value: "" },
    stateOfBirth: { label: "", value: "" },
    selectedRelation: { label: "", value: "" },
    dateOfDeath: "",
    purposeList: { label: "", value: "" },
  };
  store.state.getStartedFetalDeathCertificateData = {
    selectedRelation: { label: "", value: "" },
    selectedState: { label: "", value: "" },
    dateOfEvent: "",
  };
  store.state.fetalDeathCertificateInfoData = {
    certificateInfoType: "",
    uid: "",
    cityOfBirth: "",
    selectedGender: { label: "", value: "" },
    lastName: "",
    middleName: "",
    firstName: "",
    firstNameParent1: "",
    middleNameParent1: "",
    lastNameParent1: "",
    firstNameParent2: "",
    middleNameParent2: "",
    lastNameParent2: "",
    noOfCopies: "",
    dateOfBirth: "",
    cityOfEvent: "",
    dateOfEvent: "",
    countyOfEvent: {
      label: "",
      value: "",
    },
    selectedBirthCertificate: {
      value: "",
      label: "",
    },
    selectedPurposeList: {
      value: "",
      label: "",
    },
    orderDetails: [
      {
        recordType: "",
        numberOfCopies: 0,
      },
    ],
    totalNoOfCopies: 0,
    selectedRelation: { label: "", value: "" },
    selectedState: { label: "", value: "" },
  };
  store.setState(store.state);
};
